import { Button, Row } from "@gadgetinc/widgets";
import { CheckmarkIcon } from "@gadgetinc/widgets/src/icons/CheckmarkIcon";
import { ChevronDownIcon } from "@gadgetinc/widgets/src/icons/ChevronDownIcon";
import { NestedMenus, StatefulMenu, type ItemT } from "baseui/menu";
import { PLACEMENT, StatefulPopover } from "baseui/popover";
import { satisfies } from "compare-versions";
import { find } from "lodash";
import React, { useContext, useMemo, useState } from "react";
import { AvailableVersions, edgeVersion, latestVersion } from "state-trees/src/versioning/FrameworkVersions";
import { useFlag } from "web/src/lib/flags";
import { DocsContext } from "../DocsContext";
import type { DocsVersion, FullMetaBlob } from "../FullMetaBlob";
import { DefaultDocsVersion, DocsVersions, MultiEnvironmentDocsVersion } from "../FullMetaBlob";
import { HighlightLatest } from "../HighlightLatest";
import { useDocsLocation } from "./useDocsLocation";

export const currentDocsVersion = (location: string, currentApp: FullMetaBlob): DocsVersion => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const prefixedVersion = find(DocsVersions, (version) => version.prefix != "" && location.startsWith(version.prefix));
  if (prefixedVersion) return prefixedVersion;

  const path = location.split("#")[0];

  if (path.startsWith("/guides")) {
    return DefaultDocsVersion;
  }

  return MultiEnvironmentDocsVersion;
};

export const desiredDocsVersion = (currentApp: FullMetaBlob): DocsVersion => {
  return MultiEnvironmentDocsVersion;
};

export const isMismatchedVersionToCurrentApp = (location: string, currentApp: FullMetaBlob): boolean => {
  if (currentApp.isExample) return false;
  const currentVersion = currentDocsVersion(location, currentApp);
  const desiredVersion = desiredDocsVersion(currentApp);
  return currentVersion.id != desiredVersion.id;
};

export const DocsFrameworkVersionSelector = (props: { useFullFrameworkLabel?: boolean }) => {
  const { useFullFrameworkLabel } = props;
  const [location, setLocation] = useDocsLocation();
  const { currentApp } = useContext(DocsContext);
  const useEdgeVersion = useFlag(["internal", "edgeFrameworkVersion"]);

  const currentVersion = currentDocsVersion(location, currentApp);
  const latestFrameworkVersion = useEdgeVersion ? edgeVersion() : latestVersion();
  const latestFrameworkVersionConstraint = latestFrameworkVersion.constraint.replace("^", "");

  const options = useMemo(() => {
    return DocsVersions.reduce((acc, docVersion) => {
      const versions = AvailableVersions.filter((version) => satisfies(version.constraint.replace("^", ""), docVersion.frameworkRange));

      if (versions.length === 0) {
        return acc;
      }

      const item: ItemT = { ...docVersion, label: useFullFrameworkLabel ? `Framework ${docVersion.label}` : docVersion.label };

      // If the latest version satisfies the frameworkRange, add "Latest" to the label
      if (satisfies(latestFrameworkVersionConstraint, docVersion.frameworkRange)) {
        item.label += " - Latest";
      }

      acc.push(item);
      return acc;
    }, [] as ItemT[]);
  }, [DocsVersions, AvailableVersions]);

  const onOptionSelect = (item: ItemT) => {
    const destination = item.prefix + "/guides/getting-started";
    setLocation(destination);
  };

  const dropdownOptions = useMemo(() => {
    return options.map((option) => ({
      ...option,
      label: (
        <Row>
          <HighlightLatest text={option.label} />
          {option.id === currentVersion.id && <CheckmarkIcon style={{ marginLeft: "auto" }} />}
        </Row>
      ),
    }));
  }, [options, currentVersion.id]);

  const selected = find(options, { id: currentVersion.id });
  const [isOpen, setIsOpen] = useState(false);

  if (options.length < 2) {
    return null;
  }

  return (
    <StatefulPopover
      placement={PLACEMENT.bottomLeft}
      stateReducer={(stateChangeType, nextState) => {
        setIsOpen(nextState.isOpen);
        return nextState;
      }}
      content={({ close }) => (
        <NestedMenus>
          <StatefulMenu
            items={dropdownOptions}
            overrides={{ List: { style: { maxHeight: "300px", overflowY: "auto", width: "262px" } } }}
            onItemSelect={({ item }) => {
              onOptionSelect(item);
              close();
            }}
          />
        </NestedMenus>
      )}
    >
      <Button data-testid="docs-version-selector" size="compact" kind="secondary" $pressed={isOpen} endEnhancer={() => <ChevronDownIcon />}>
        <HighlightLatest text={selected.label} />
      </Button>
    </StatefulPopover>
  );
};
