import { useStyletron } from "baseui";
import { StyledLink } from "baseui/link";
import { Link } from "fastify-renderer/client/react";
import type { ReactNode } from "react";
import React, { useContext } from "react";
import type { StyleObject } from "styletron-react";
import { DocsContext } from "../chrome/DocsContext";
import { currentDocsVersion } from "../chrome/nav/DocsVersionSelector";
import { isInternalDocsLink, useDocsLocation } from "../chrome/nav/useDocsLocation";

export const DocLink = ({ children, href, $style, ...rest }: { children: ReactNode; href: string; $style?: StyleObject }) => {
  const [_css] = useStyletron();
  const [location] = useDocsLocation();
  const { currentApp } = useContext(DocsContext);

  const styleOverride: StyleObject = {
    fontWeight: "inherit",
    textDecoration: "none",
    lineHeight: "0px",
    marginTop: "0px",
    ...$style,
  };

  if (isInternalDocsLink(href)) {
    if (href.startsWith("/guides")) {
      const currentVersion = currentDocsVersion(location, currentApp);
      href = currentVersion.prefix + href;
    }

    return (
      <Link href={href}>
        <StyledLink $style={styleOverride} {...rest}>
          {children}
        </StyledLink>
      </Link>
    );
  }

  return (
    <StyledLink href={href} target="_blank" $style={styleOverride} {...rest}>
      {children}
    </StyledLink>
  );
};
